.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  padding: 20px;
}

.left, .right {
  flex: 1;
  min-width: 300px;
  padding: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item {
  margin-bottom: 10px;
  color: #555;
  font-size: 14px;
}

.menu-item.selected {
  font-weight: bold;
  color: black;
}

.right .feature {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.feature h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #000000;
}

.feature p {
  margin: 5px 0 0;
  color: #000000;
  font-size: 14px;
}